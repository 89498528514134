<template>
    <div>
        <CDataTable
            :items="alertLogs"
            :fields="alertLogFields"
            :items-per-page="10"
            :loading="loading"
            class="modalTable"
            hover
            pagination
        >
            <template #country="{item}">
            <td> {{ item.country.name }}</td>
            </template>
            <template #createTime="{item}">
                <td> <span>{{ item.createTime &&  dateFormat(item.createTime) }}</span> </td>
            </template>
        </CDataTable> 
    </div>
</template>

<script>
    import moment from 'moment';
    export default{
        name: "CustomerAlertLogs",
        props:{
            params: Object,
        },
        computed:{
            alertLogs: function() {
                return this.$store.getters.customerAlertLogs
            },
            loading: function() {
                return this.$store.getters.socialLoading
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                alertLogFields: [
                    { key: 'id', label: 'ID', _style: 'font-size:12px' },
                    { key: 'createTime', label: 'Tarih', _style: 'font-size:12px' },
                    { key: 'level', label: 'LOG', _style: 'font-size:12px' },
                    { key: 'log', label: 'Seviye', _style: 'font-size:12px' },
                    { key: 'alerted', label: 'Alarm', _style: 'font-size:12px' }
                ],
            }
        },
        methods: {
            dateFormat(date){
                return moment(date).format("DD.MM.YYYY HH:mm:ss")
            },
        }
    }
</script>